<!-- <div *ngIf="!verified && isLoggedIn && !isMobile" class="m-0 sub-header sub-header-warning">
	Your email address is not verified yet, some functions will be restricted until you do verify your email address. <button mat-stroked-button color="primary" (click)="reSendValidation()">Re-send verification code.</button>
</div> -->
<mat-toolbar  class="mat-elevation-z1" [ngClass]="fuseConfig?.layout?.toolbar?.customToolbarBackground ? null : fuseConfig?.layout?.toolbar?.background" [style.background-color]="fuseConfig?.layout?.toolbar?.customToolbarBackground" [style.color]="fuseConfig?.layout?.toolbar?.customToolbarForeground">
	<div id="toolbar" style="display:flex;flex-direction:row;place-content:center flex-start;align-items: center;flex:1 1 0%;">
		<!-- LOGO when the layout is horizontal-->
		<img [src]="fuseConfig?.layout.toolbar.toolBarLogo" fxHidelt-lg class="ml-8" *ngIf="(fuseConfig?.layout.style == 'horizontal-layout-1') && fuseConfig?.layout.toolbar.toolBarLogo" />
		<!-- LEFT SIDE MENU -->
		<div style="display:flex;flex-direction: row;place-content: center flex-start;box-sizing: border-box;align-items: center;flex: 1 0 auto;">
			<div [ngClass]="{'pl-8': !isMobile, 'p-4': isMobile}">
				<!-- Fold Desktop Menu -->
				<!-- <button *ngIf="!horizontalNavbar && !isMobile" mat-icon-button class="toggle-sidebar-folded" (click)="toggleSidebarFolded()">
					<mat-icon [class.secondary-text]="!fuseConfig?.layout?.toolbar?.customToolbarForeground" [style.color]="fuseConfig?.layout?.toolbar?.customToolbarForeground">menu_open</mat-icon>
				</button> -->
				<span *ngIf="!horizontalNavbar && !isMobile" (click)="toggleSidebarFolded()" class="material-icons"  [class.secondary-text]="!fuseConfig?.layout?.toolbar?.customToolbarForeground" [style.color]="fuseConfig?.layout?.toolbar?.customToolbarForeground"> menu_open </span>


				<!-- Toggle sidebar menu on Mobile -->
				<!-- <button *ngIf="(horizontalNavbar && isMobile) || isMobile" mat-icon-button class="toggle-sidebar-opened" (click)="toggleSidebarOpened()">
					<mat-icon [class.secondary-text]="!fuseConfig?.layout?.toolbar?.customToolbarForeground" [style.color]="fuseConfig?.layout?.toolbar?.customToolbarForeground">menu_open</mat-icon>
				</button> -->

				<span *ngIf="(horizontalNavbar && isMobile) || isMobile"  (click)="toggleSidebarOpened('navbar')" class="material-icons mt-8" [class.secondary-text]="!fuseConfig?.layout?.toolbar?.customToolbarForeground" [style.color]="fuseConfig?.layout?.toolbar?.customToolbarForeground"> menu_open </span>
			</div>

			<!-- SEPARATOR PIPE-->
			<div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHidegt-md></div>

			<!-- SOCIAL SHARE -->
			<div class="container px-8 px-md-16" *ngIf="!shareOnHidden && !isOneNation && !isMobile">
				<div class="shareOn">Share On</div>
				<div class="social-icons">
					<ul>
						<li>
							<a [href]="twtURL" (click)="recordEvent('twitter')" target="_blank">
								<i class="icon-twitter"></i>
							</a>
						</li>
						<li>
							<a [href]="fbURL" (click)="recordEvent('facebook')" target="_blank">
								<i class="icon-facebook"></i>
							</a>
						</li>
					</ul>
				</div>
			</div>

			<!-- SHORT CUTS -->
			<fuse-shortcuts [navigation]="navigation"></fuse-shortcuts>
			<!-- ######## Turned off until we have team & player options ready tD - Jun 4'2020 -->
			<!-- <div class="px-8 px-md-16">
				<ng-container *ngIf="isLoggedIn">
					<button [fxHide]="mobileQuery" [matMenuTriggerFor]="addNewMenu" mat-icon-button type="button" [@animate]="{value:'*',params:{delay:'150ms',x:'-100px'}}">
						<tinc-icon icon="add"></tinc-icon>
						<span fxHide fxShow.gt-md class="ml-8">ADD NEW</span>
					</button>

					<mat-menu class="toolbarSubMenu" #addNewMenu="matMenu" [overlapTrigger]="false" xPosition="after" yPosition="below">
						<button mat-menu-item *ngIf="persona.director || persona.hq || persona.tinc">
							<mat-icon aria-hidden="false" aria-label="Add Event" class="accent-A700-fg">add</mat-icon><a href routerLink="/pages/create">Add Event</a>
						</button>
						<button mat-menu-item *ngIf="isLoggedIn">
							<mat-icon aria-hidden="false" aria-label="Add Team" class="accent-A700-fg">add</mat-icon><a href routerLink="/pages/create-team">Add Team</a>
						</button>
						<button mat-menu-item *ngIf="isLoggedIn">
							<mat-icon aria-hidden="false" aria-label="Add Player" class="accent-A700-fg">add</mat-icon><a href routerLink="/pages/add-player">Add Player</a>
						</button>
						<button mat-menu-item *ngIf="persona.director || persona.hq || persona.tinc">
							<mat-icon aria-hidden="false" aria-label="Add Facility" class="accent-A700-fg">add</mat-icon><a href routerLink="/pages/facilities">Add Facility</a>
						</button>
					</mat-menu>
				</ng-container>
			</div> -->
			<!-- ./SHORT CUTS -->
		</div>
		<!-- ./LEFT SIDE MENU -->
		<!-- LOGO ON MOBILE VIEW -->
		<div *ngIf="isMobile" style="flex-direction: row;box-sizing: border-box;display: flex;place-content: center flex-start;align-items: center;justify-content: center;flex: 1 1 100%;max-width: 68%;">
			<a [routerLink]="['/pages/home']" class="text-center">
				<img class="logo-primary mx-auto" [src]="fuseConfig ? fuseConfig?.smallLogo : '/assets/images/logos/mobile-logo.png'">
			</a>
		</div>
		<!-- RIGHT SIDE MENU -->
		<div class="" style="display:flex;flex-direction: row;place-content: center flex-start;align-items: center;flex: 0 1 auto;">
			<div class="toolbar-separator"></div>
			<!-- SEARCH -->
			<!-- <fuse-search-bar (input)="search($event)"></fuse-search-bar> -->
			<!-- NOTIFICATIONS -->
			<!-- <button mat-icon-button class="quick-panel-toggle-button" (click)="toggleSidebarOpen('notificationPanel')" aria-label="Toggle notifications">
				<tinc-icon icon="notification"></tinc-icon>
            </button> -->
			
			<!-- QUICK PANEL ON RIGHT -->
			<!-- <button mat-icon-button fxHide.gt-md class="chat-panel-toggle-button" (click)="toggleSidebarOpened('chatPanel')" aria-label="Toggle chat panel">
				<mat-icon class="secondary-text">chat</mat-icon>
			</button> -->

			<!-- <button mat-icon-button class="quick-panel-toggle-button mr-12" (click)="toggleSidebarOpen('quickPanel')" aria-label="Toggle quick panel">
				<tinc-icon icon="bookmark"></tinc-icon>
            </button> -->

			<!-- Return from Impersonate -->
			<span *ngIf="showResetImpersonate" class="material-icons" [ngClass]="{'mx-8': !isMobile}" (click)="resetImpersonate()" matTooltip="Return back to your account" matTooltipClass="mat-tooltip-big"> replay </span>
			<!-- PERSON -->
			<div class="toolbar-separator"></div>
			<!-- IF Logged-In -->
			<div #originMyAccount class="pl-4" [class.bg-hover]="dropdownOpen" *ngIf="isLoggedIn && persona" [@animate]="{value:'*',params:{delay:'250ms',x:'300px'}}">
				<div style="display:flex;flex-direction:row;place-content:center;align-items:center">
					<button mat-button (click)="showPopover(originMyAccount, 'myAccount')" class="" style="padding:0 !important;">
						<div style="display:flex;flex-direction:row;place-content:center;align-items:center">
							<ng-container *ngIf="!isMobile">
								<img *ngIf="userProfile.avatar" class="avatar mr-0 mr-sm-16" [src]="imagePrefix + userProfile.avatar">
								<mat-icon *ngIf="!userProfile.avatar" class="avatar avatar-icon primary-fg">account_circle</mat-icon>
								<span class="mr-12" fxHide-xs [class.username]="!fuseConfig?.layout?.toolbar?.customToolbarForeground" [style.color]="fuseConfig?.layout?.toolbar?.customToolbarForeground">{{person.firstName}} {{person.lastName}}</span>
							</ng-container>
							<ng-container *ngIf="isMobile">
								<span class="bold initials" [class.username]="!fuseConfig?.layout?.toolbar?.customToolbarForeground" [style.color]="fuseConfig?.layout?.toolbar?.customToolbarForeground">{{person.initials}}</span>
							</ng-container>
							<mat-icon class="bold font-size-16" [class.username]="!fuseConfig?.layout?.toolbar?.customToolbarForeground" [style.color]="fuseConfig?.layout?.toolbar?.customToolbarForeground" fxHide-xs>keyboard_arrow_down</mat-icon>
						</div>
					</button>
					<!-- <span class="username mr-12" fxHide fxShow.gt-sm>{{person.firstName}} {{person.lastName}}</span>
				<div class="avatar h-9 w-9 flex items-center justify-center"
					style="background-color: rgba(92, 119, 255, 0.098); color: rgb(92, 119, 255);">
					<img *ngIf="userProfile.avatar" [src]="imagePrefix + userProfile.avatar" class="avatar" /><br>
					<tinc-icon *ngIf="!userProfile.avatar" icon="user-circle-o"></tinc-icon>
				</div>
				<tinc-icon class="" fxHide-xs icon="down"></tinc-icon> -->
				</div>
			</div>
			<!-- If NOT Logged-In -->
			<!-- showPopover(originLogin, 'login') -->
			<div *ngIf="(!isLoggedIn || !persona) && !isMobile" #originLogin (click)="openLogin(originLogin)" 
				class="user-button pl-8 cursorPointer flex items-center rounded cursor-pointer relative trans-ease-out select-none py-1 pr-1 pl-3 hover:bg-hover" fx 
				[class.bg-hover]="dropdownOpen" 
				fxLayout="row" 
				fxLayoutAlign="center center" 
				[@animate]="{value:'*',params:{delay:'150ms',x:'-100px'}}">
					<span class="bold mr-8">SIGN IN</span>&nbsp; 
					<span class="material-icons"> login </span>
			</div>
			<a *ngIf="(!isLoggedIn || !persona) && isMobile"  #originLogin (click)="openLogin(originLogin)" color="primary" mat-button [ngClass]="{'w-100-p': !isMobile}" style="justify-content: flex-start;">
				<span class="material-icons"> login </span>
			</a>
			<!-- /Person -->
			<!-- <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div> -->
		</div>
		<!-- ./RIGHT SIDE MENU -->
		<!-- Global Search -->
		<tinc-gsr [keyword]="_keyword"></tinc-gsr>
	</div>
</mat-toolbar>
